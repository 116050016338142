@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format('woff2');
}
body {
  margin: 0;
  font-family: Product Sans;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1em;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  @apply text-center flex justify-center items-center bg-gradient-to-tr from-slate-800 to-slate-900;
  height: 100vh;
}
.login-container {
  @apply flex lg:flex-row flex-col items-center justify-center lg:w-1/2 w-4/5  my-auto shadow-lg bg-gray-200 rounded-xl;
}
.login-form-container,
.signup-refer-container {
  @apply flex flex-col items-center justify-center text-center w-full lg:w-1/2;
}
.login-form-container {
  @apply p-6;
}
.signup-refer-container {
  @apply bg-gradient-to-r from-slate-600 rounded-r-xl to-slate-700;
  height: 28em;
}
.refer-btn {
  @apply bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold rounded-full p-3 px-10;
}
.admin-dashboard {
  @apply w-full h-full bg-gray-100 flex flex-row justify-start;
}
.drawer-container {
  @apply items-center fixed left-0 text-center w-auto h-full bg-sky-500 py-6 p-2 shadow-md;
}
.drawer-content-item {
  @apply my-5 text-white;
}
.drawer-button {
  @apply text-white hover:bg-white shadow-md mx-3;
}
.drawer-icon {
  @apply text-white;
}
.tooltip {
  @apply text-white bg-black;
}
.admin-dashboard-content {
  @apply flex-col items-center justify-center text-center w-screen bg-gray-200 min-h-full max-h-fit p-4 overflow-hidden;
}
.admin-dashboard-header {
  @apply w-4/5 flex lg:flex-row flex-col justify-between mx-auto mb-5;
}
.header-profile-container {
  @apply flex flex-row;
}
.profile-avatar {
  @apply lg:w-28 lg:h-28 sm:mt-5 rounded-full;
}
.account-avatar {
  @apply lg:w-20 lg:h-20 w-10 h-10 rounded-full;
}
.header-menu-container {
  @apply flex flex-row justify-around items-center;
}
.account-menu-container {
  @apply flex flex-row justify-around items-center;
}
.search-input {
  @apply h-10 px-5 rounded-full outline-none shadow-inner;
}
.admin-dashboard-main {
  @apply lg:w-4/5 w-11/12 flex lg:flex-row pb-5 flex-col my-5 justify-around mx-auto overflow-y-auto;
}
.admin-dashboard-main-item {
  @apply shadow-md rounded-2xl;
}
.accounts-list-container {
  @apply flex flex-col items-center ml-32 mt-5 px-5;
  height: 70%;
}
.accounts-list {
  @apply flex flex-col items-center w-full px-5 overflow-y-scroll;
}
.account-list-item {
  @apply flex lg:flex-row flex-col bg-gray-300 hover:bg-[#d3d2d2] p-3 rounded-lg items-start w-full justify-between mb-3;
}
.account-profile-container {
  @apply flex lg:flex-row flex-col items-start justify-between;
}
.account-detail-container {
  @apply flex flex-col items-start text-left px-3;
}
.modal-content {
  @apply w-1/2 absolute -top-80 transform translate-x-1/2 translate-y-1/2 bg-gray-100 p-3 rounded-lg;
}
.file-upload-container {
  @apply bg-white p-4 px-6 rounded-2xl shadow-md items-center text-center flex justify-center flex-col;
}
.file-upload-button-container {
  @apply mt-3;
}
.alert-container {
  @apply flex absolute top-32 left-80 bg-red-400;
}
@media screen and (max-width: 768px) {
  .signup-refer-container {
    @apply rounded-none rounded-b-xl;
    height: 10em;
  }
  .App {
    height: 110vh;
  }
}

.search-input::-webkit-input-placeholder,
.create-profile-btn span {
  font-family: FontAwesome;
  font-weight: lighter;
  font-size: 1em;
  overflow: visible;
  display: inline-block !important;
  vertical-align: top;
  padding-left: 5px;
  padding-top: 7px;
  color: #3f3d566b;
}
.search-input::-moz-placeholder,
.create-profile-btn span {
  font-family: FontAwesome;
  font-weight: lighter;
  font-size: 1em;
  overflow: visible;
  display: inline-block !important;
  vertical-align: top;
  padding-left: 5px;
  padding-top: 7px;
  color: #3f3d566b;
}
.search-input:-ms-input-placeholder,
.create-profile-btn span {
  font-family: FontAwesome;
  font-weight: lighter;
  font-size: 1em;
  overflow: visible;
  display: inline-block !important;
  vertical-align: top;
  padding-left: 5px;
  padding-top: 7px;
  color: #3f3d566b;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
